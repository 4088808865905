import React, { createContext, useState, useEffect } from "react";
import questionsData from "../data/questionsData copy";

export const QuizContext = createContext();

export const QuizProvider = ({ children }) => {
  const [userAnswers, setUserAnswers] = useState([]);

  return (
    <QuizContext.Provider
      value={{
        userAnswers,
        setUserAnswers,
      }}
    >
      {children}
    </QuizContext.Provider>
  );
};
